import axios from "axios";

// 项目总览
export function querySupplierProjectOverview(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/supplier/querySupplierProjectOverview.html", params)
}

// 物资/工程/服务
export function querySupplierProjectInfoByBidType(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/supplier/querySupplierProjectInfoByBidType.html", params)
}

// 月度投标统计
export function querySupplierTenderProjectAmountByMonth(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/supplier/querySupplierTenderProjectAmountByMonth.html", params)
}