<template>
    <el-form ref="form" :model="form" label-width="80px" class="report-procurement">
        <div class="row01">
            <div class="row01-line01" style=" flex: 1;">
                <div class="grid-content bg-purple">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>项目总览</span>
                            <el-date-picker size="mini" style="float:right" v-model="placeholderTime"
                                @change="xmzlChange" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                        <div v-show="!isLoadingzl" class="text item"
                            style="display:flex;flex-wrap: wrap;height: 150px;align-items: center;justify-content: center;">
                            <div v-for="(item, index) in project" :key="index" class="report-procurement-pro-card"
                                :style="{ backgroundColor: colorRgb(item.bcolor) }">
                                <div class="report-procurement-icon" :style="{ backgroundColor: item.bcolor }"><i
                                        :class="item.icon" /></div>
                                <div>
                                    <div><span style="font-weight: 600;font-size: 18px;">{{ item.value }}</span><span
                                            style="font-size:12px;color:#909399">{{ item.nuit }}</span>
                                    </div>
                                    <div>{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                        <el-skeleton v-show="isLoadingzl" :rows="5" style="height:100%" />
                    </el-card>
                </div>
            </div>
            <div class="row01-line01 report-procurement-detail" style=" flex: 1;min-height: 260px;">
                <div class="grid-content bg-purple">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <el-tabs v-model="activeName" @tab-click="tabsChangge">
                                <el-tab-pane label="物资" name="10" />
                                <el-tab-pane label="工程" name="20" />
                                <el-tab-pane label="服务" name="30" />
                            </el-tabs>
                        </div>
                        <div v-show="!isLoadingzj" style="display:flex;justify-content: center;">
                            <div v-for="(item,index) in detail" :key="index" class="report-procurement-detail-card">
                                <div class="report-detail-card-head" :style="{backgroundColor: colorRgb(item.bcolor)}">
                                    {{item.name}}</div>
                                <div class="report-detail-card-body" :style="{backgroundColor: colorRgb(item.bcolor)}">
                                    <span style="font-weight: 600;font-size: 20px;">{{item.value}}</span><span
                                        style="font-size:12px;">{{item.nuit}}</span>
                                </div>
                            </div>
                        </div>
                        <el-skeleton v-show="isLoadingzj" :rows="3" style="height:100%" />
                    </el-card>
                </div>
            </div>


        </div>
        <div class="row02" style="min-height: 438px;">
            <div class="row02-line01" style=" flex: 1;min-width:930px;">
                <div class="grid-content bg-purple">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>月度投标统计</span>
                                <el-date-picker style="float:right" size="mini" v-model="ydzctjValue" type="year"
                                    @change="ydzctjChange" placeholder="选择年">
                                </el-date-picker>
                            </div>
                            <div v-show="!isLoadingzc" class="text item" style="display:flex;flex-wrap: wrap;">
                                <!-- <div id="ydzctjEcharts" style="height:100px;width:100px;" /> -->
                                <div id="ydzctjEcharts" style="width: 864px;height:474px"></div>
                            </div>
                            <el-skeleton v-show="isLoadingzc" :rows="10" style="height:100%" />
                        </el-card>
                    </div>
                </div>

            </div>
            <div class="row02-line02" style=" flex: 1;">
                <div class="grid-content bg-purple">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>采购物资价格趋势</span>
                                <div style="float:right;display:flex;">
                                    <el-date-picker size="mini" v-model="value3" type="year" placeholder="选择年">
                                    </el-date-picker>
                                    <el-input v-model="input" size="mini" placeholder="请输入物资">
                                    </el-input>
                                </div>
                            </div>
                            <div class="text item">
                                <!-- <div id="cgwzjgqsEcharts" style="width: 864px;height:374px"></div> -->
                                <el-empty :image-size="200" style="width: 864px;height:474px"></el-empty>
                            </div>
                        </el-card>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import { nextTick } from 'vue';
import { querySupplierProjectOverview, querySupplierProjectInfoByBidType, querySupplierTenderProjectAmountByMonth } from "@/api/report/supplier";
import moment from 'moment';
export default {
    data() {
        return {
            isLoadingzj: false,
            isLoadingzl: false,
            isLoadingzc: false,
            ydzctjValue: "",
            placeholderTime: [],
            myChart: null,
            project: [
                { name: "累计投标项目", value: 180, nuit: "个", icon: 'el-icon-tickets', bcolor: '#E6A23C' },
                { name: "累计中标项目", value: 160, nuit: "个", icon: 'el-icon-present', bcolor: '#F56C6C' },
                { name: "累计中标金额", value: 500, nuit: "万元", icon: 'el-icon-coin', bcolor: '#8dc63f' }
            ],
            detail: [
                { name: "进行中", value: 25, nuit: "个", bcolor: '#6739b6' },
                { name: "已完结", value: 120, nuit: "个", bcolor: '#8799a3' },
                { name: "已中标", value: 0, nuit: "个", bcolor: '#1cbbb4' },
                { name: "未中标", value: 53, nuit: "个", bcolor: '#9c26b0' },
            ],
            activeName: '10',
            form: {

            }
        }
    },
    created() {
        this.getSupplierProjectOverviewData()
        this.getSupplierProjectInfoByBidTypeData(10);
        this.getSupplierTenderProjectAmountByMonthData()
        console.log(this.$route.query.agentId)

        this.$nextTick(() => {
            // 基于准备好的dom，初始化echarts实例
            // var myChart2 = this.$echarts.init(document.getElementById('cgwzjgqsEcharts'));
            // 指定图表的配置项和数据

            // 使用刚指定的配置项和数据显示图表。

            let data = [1600, 2400, 2800, 3200, 3600, 4600, 4000, 3800, 3600, 3400, 3500, 3600];
            let olddata = [1500, 2500, 2700, 3400, 3600, 4500, 4001, 3805, 3609, 3500, 3600, 3100];
            let options2 = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    },
                    formatter: function (params) {
                        let str = '';
                        params.forEach((item, idx) => {
                            str += `${item.marker} ${item.data}<br/>`;
                            switch (idx) {
                                case 0:
                                    // 今年第一月
                                    if (item.dataIndex == 0) {
                                        // 和去年最后一月相比
                                        if (item.data - olddata[olddata.length - 1] < 0) {
                                            str += `同比:<span style="color:#F56C6C;">-${((olddata[olddata.length - 1] - item.data) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        } else if (item.data - olddata[olddata.length - 1] == 0) {
                                            str += '同比:0%<br/>';
                                        } else {
                                            str += `同比:<span style="color:#67C23A;">+${((item.data - olddata[olddata.length - 1]) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        }
                                    } else {
                                        // 和上一月相比
                                        if (item.data - data[item.dataIndex - 1] < 0) {
                                            str += `同比:<span style="color:#F56C6C;">-${((data[item.dataIndex - 1] - item.data) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        } else if (item.data - data[item.dataIndex - 1] == 0) {
                                            str += '同比:0%<br/>';
                                        } else {
                                            str += `同比:<span style="color:#67C23A;">+${((item.data - data[item.dataIndex - 1]) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        }
                                    }
                                    // 和去年相比
                                    if (item.data - olddata[item.dataIndex] < 0) {
                                        str += `同比:<span style="color:#F56C6C;">-${((olddata[item.dataIndex] - item.data) / item.data * 100).toFixed(2)}%</span><br/>`;
                                    } else if (item.data - olddata[item.dataIndex] == 0) {
                                        str += '同比:0%<br/>';
                                    } else {
                                        str += `同比:<span style="color:#67C23A;">+${((item.data - olddata[item.dataIndex]) / item.data * 100).toFixed(2)}%</span><br/>`;
                                    }
                                    break;
                                default:
                                // str += 'w(ﾟДﾟ)w';
                            }
                            str += idx === params.length - 1 ? '' : '<br/>';
                        });
                        return str;
                    }
                },
                xAxis: {
                    type: 'category',
                    data: ['1 ', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                    axisLabel: {
                        textStyle: {
                            color: '#303133'
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#303133'
                        }
                    }
                },
                series: [
                    {
                        data: data,
                        type: 'line',
                        symbol: 'none'
                    },
                    {
                        data: olddata,
                        type: 'line',
                        symbol: 'none'
                    }
                ]
            }
            // myChart2.setOption(options2);
        })
    },
    methods: {
        ydzctjChange(e) {
            this.getSupplierTenderProjectAmountByMonthData(moment(e.getTime()).format("YYYY"))
        },
        // 月度投标统计
        getSupplierTenderProjectAmountByMonthData(year) {
            let query = { supplierId: this.$route.query.supplierId };
            if (year) {
                query.year = year;
            }
            let that = this;
            this.isLoadingzc = true;
            querySupplierTenderProjectAmountByMonth(this.member, query).then(res => {
                this.isLoadingzc = false;
                if (res.status == 200) {
                    // 成交额
                    let data1 = []
                    // 招采项目数
                    let data2 = []
                    for (const key in res.data.yearTenderAward) {
                        const element = res.data.yearTenderAward[key];
                        data1.push(element)
                    }

                    for (const key in res.data.yearTenderCount) {
                        const element = res.data.yearTenderCount[key];
                        data2.push(element)
                    }

                    // 月度招采统计
                    var myChart = this.$echarts.init(document.getElementById('ydzctjEcharts'));
                    // 指定图表的配置项和数据
                    var options = {
                        legend: {
                            data: ['成交额', '投标项目数量'],
                            textStyle: {
                                color: '#303133'
                            }
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            }
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                                axisPointer: {
                                    type: 'shadow'
                                },
                                axisLabel: {
                                    textStyle: {
                                        color: '#303133'
                                    }
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '',
                                // min: 0,
                                // max: 5000,
                                // interval: 500,
                                axisLabel: {
                                    textStyle: {
                                        color: '#303133'
                                    },
                                    formatter: function (value, index) {
                                        return that.setAxisLabel(value);
                                    }
                                }
                            },
                            {
                                type: 'value',
                                name: '',
                                // min: 0,
                                // max: 50,
                                // interval: 5,
                                axisLabel: {
                                    //y轴文字的配置
                                    textStyle: {
                                        color: '#303133'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: '成交额',
                                type: 'bar',
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data1
                            },
                            {
                                name: '投标项目数量',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data2
                            }
                        ]
                    };
                    // 使用刚指定的配置项和数据显示图表。
                    myChart.setOption(options);
                }
            })
        },
        tabsChangge(e) {
            this.getSupplierProjectInfoByBidTypeData(e.name);
        },
        xmzlChange(e) {
            this.getSupplierProjectOverviewData(e);
        },
        // 物资/工程/服务
        getSupplierProjectInfoByBidTypeData(bidType) {
            let query = { bidType: bidType, supplierId: this.$route.query.supplierId };
            this.isLoadingzj = true;
            querySupplierProjectInfoByBidType(this.member, query).then(res => {
                this.isLoadingzj = false;
                if (res.status == 200) {
                    let temdetail = [
                        { name: "进行中", value: this.setNumber(res.data.progressProject), nuit: "个", bcolor: '#6739b6' },
                        { name: "已完结", value: this.setNumber(res.data.finishedProject), nuit: "个", bcolor: '#8799a3' },
                        { name: "已中标", value: this.setNumber(res.data.awardProject), nuit: "个", bcolor: '#1cbbb4' },
                        { name: "未中标", value: this.setNumber(res.data.noAwardProject), nuit: "个", bcolor: '#9c26b0' },
                    ]
                    this.detail = temdetail;
                }
            }
            )
        },
        // 项目总览
        getSupplierProjectOverviewData(time) {
            let query = { supplierId: this.$route.query.supplierId };
            if (time && time.length == 2) {
                query.startTime = moment(time[0].getTime()).format("YYYY-MM-DD HH:mm:ss");
                query.endTime = moment(time[1].getTime()).format("YYYY-MM-DD HH:mm:ss");
            }
            this.isLoadingzl = true;
            querySupplierProjectOverview(this.member, query).then(res => {
                this.isLoadingzl = false;
                if (res.status == 200) {
                    let temProject = [
                        { name: "累计投标项目", value: this.setNumber(res.data.sendProject), nuit: "个", icon: 'el-icon-tickets', bcolor: '#E6A23C' },
                        { name: "累计中标项目", value: this.setNumber(res.data.awardProject), nuit: "个", icon: 'el-icon-present', bcolor: '#F56C6C' },
                        { name: "累计中标金额", value: this.setNumber(res.data.totalProjectPrice), nuit: this.setNumber(res.data.totalProjectPrice, true), icon: 'el-icon-coin', bcolor: '#8dc63f' }
                    ]
                    this.project = temProject;
                }
            })
        },
        setNumber(value, isUnit = false) {
            if (isUnit) {
                if (Math.abs(value) < 10000) {
                    return "元";
                } else if (Math.abs(value) < 100000000) {
                    return "万元";
                } else if (Math.abs(value) < 1000000000000) {
                    return "亿元";
                } else {
                    return "万亿";
                }
            } else {
                if (Math.abs(value) < 10000) {
                    return value;
                } else if (Math.abs(value) < 100000000) {
                    return (value / 10000).toFixed(2);
                } else if (Math.abs(value) < 1000000000000) {
                    return (value / 100000000).toFixed(2);
                } else {
                    return (value / 1000000000000).toFixed(2);
                }
            }
        },
        setAxisLabel(value) {
            if (Math.abs(value) < 10000) {
                return value;
            } else if (Math.abs(value) < 100000000) {
                return (value / 10000).toFixed(1) + "万";
            } else if (Math.abs(value) < 1000000000000) {
                return (value / 100000000).toFixed(1) + "亿";
            } else {
                return (value / 1000000000000).toFixed(1) + "万亿";
            }
        },
        colorRgb(colorStr) {
            //十六进制颜色值的正则表达式
            var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
            var sColor = colorStr.toLowerCase();
            if (sColor && reg.test(sColor)) {
                if (sColor.length === 4) {
                    var sColorNew = "#";
                    for (var i = 1; i < 4; i += 1) {
                        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
                    }
                    sColor = sColorNew;
                }
                //处理六位的颜色值f
                var sColorChange = [];
                for (var i = 1; i < 7; i += 2) {
                    sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
                }
                return "rgb(" + sColorChange.join(" ") + ' / 10%' + ")";
            } else {
                return sColor;
            }
        }
    }
}
</script>

<style scoped>
.el-card {
    font-size: 14px;
}

.report-procurement>>>.el-skeleton {
    background-color: #fff;
    padding: 10px;
    height: 100%;
}

@media screen and (max-width: 1200px) {

    /* .row02-line01,
    .row01-line01 {
        margin-bottom: 20px;
    } */

    .report-procurement {
        height: 100%;
    }

    .row02,
    .row01 {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .row02-line02,
    .row02-line01,
    .row01-line02,
    .row01-line01 {
        min-width: 930px;
        width: 60%;
        /* margin: 0 auto 20px auto; */
    }
}

.report-procurement {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

@media screen and (max-width: 1920px) and (min-width: 1200px) {
    .report-procurement {
        height: 100vh;
    }

    .row01 {
        width: 100%;
        flex: 3;
    }

    .row02 {
        width: 100%;
        flex: 7;
    }

    .row02,
    .row01 {
        display: flex;
        gap: 20px;
    }
}

@media (min-width: 1920px) {
    .report-procurement {
        height: 937px;
    }

    .row01 {
        width: 100%;
        flex: 3;
    }

    .row02 {
        width: 100%;
        flex: 7;
    }

    .row02,
    .row01 {
        display: flex;
        gap: 20px;
    }

    /* .row01-line01,
    .row02-line01 {
        margin-right: 20px;
    } */
}

.procurement-table-body01>>>.el-card__body,
.el-main {
    width: 100% !important;
}

.procurement-table-body02>>>.el-card__body,
.el-main {
    width: 100% !important;
}

.report-procurement>>>.el-card__body,
.el-main {
    width: 925px;
}

.report-procurement-detail>>>.el-card__body {
    height: 180px;
}

.report-procurement-detail>>>.el-tabs__header {
    margin: 0;
}

.report-procurement-detail>>>.el-tabs__nav-wrap::after {
    display: none;
}

.report-procurement-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0.5; */
    font-size: 25px;
    color: #fff;
    margin-right: 10px;
}

.report-procurement-table>>>.el-table--fit {
    height: 356px !important;
}

.report-procurement-pro-card {
    display: flex;
    margin-right: 2%;
    padding: 5px 10px 5px 20px;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
    height: 100px;
}

.report-procurement-detail-card {
    /* background-color: #ffe0ff; */
    height: 100px;
    width: 150px;
    margin: 0 4%;
    border-radius: 5px;
}

.report-detail-card-head::after {
    content: '';
    width: 140px;
    height: 1px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
}

.report-detail-card-head {
    background-color: rgb(255 224 255 / 50%);
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 10px 10px;
    position: relative;
}

.report-detail-card-body {
    background-color: rgb(255 224 255 / 50%);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
}

.report-procurement>>>.el-col-12,
.report-procurement>>>.grid-content,
.report-procurement>>>.el-card {
    height: 100% !important;
    width: 100% !important;
}
</style>